module.exports = {
  /**
  * @description 审核状态
  */
  USER_AUDIT_FLAG: {
    UNDER_APPROVAL: 0,
    // 审核中
    ATTENTION_DOC: 1,
    // 资料审核驳回
    DOC_VERIFIED: 2,
    // 资料审核通过
    REFRENCE_CHECK: 3,
    // refrence check通过
    ONLINE_MEETING: 4,
    // 线上面试通过
    PENDING_APPROVAL: 5,
    // 待上级审核
    APPROVED: 6,
    // 审核通过
    REJECTED: 7 // 审核驳回

  },

  /**
   * @description 审核状态
   */
  USER_AUDIT_STATUS: {
    APPROVED: 1,
    // 审核通过
    DISAPPROVED: 2,
    // 驳回
    UNDER_APPROVAL: 3 // 待审核

  },

  /**
   * @description offer状态
   */
  OFFER_STATUS: {
    CANCELLED: 0,
    // 已取消
    INTERESTED: 1,
    // 感兴趣interested
    CONNECTING: 2,
    // 待接收
    EXPIRED: 3,
    // 已超期
    REJECTED: 4 // 已拒绝

  },

  /**
   * @description job invite状态
   */
  JOB_INVITE_STATUS: {
    CANCELLED: 0,
    // 已取消
    CONNECTED: 1,
    // 已连接
    CONNECTING: 2,
    // 连接中
    FAILED: 3,
    // 匹配失败
    SPLITTED: 4 // 拆单

  },

  /**
   * @description job Book状态
   */
  JOB_BOOK_STATUS: {
    PROCESSING: 2,
    // 进行中
    FINISHED: 3,
    // 已完成
    LEAVE: 4,
    // 请假
    CANCELLED: 5,
    // 取消
    EXTEND: 6 // 延时

  },

  /**
   * @description notes状态
   */
  EDIT_NOTES_TYPE: {
    CENTER: 1,
    // 机构
    TEACHER: 2,
    // 教师
    CENTER_POOL: 3,
    // 机构资源池
    TEACHER_POOL: 4 // 教师资源池

  },

  /**
   * @description 机构risk状态
   */
  RISK_STATUS_TYPE: {
    SIGNUP_NO_BOOKING: 7,
    // 注册未预订
    NEW_SIGNUP: 6,
    // 新注册
    HIGH_RISK: 5,
    // 高风险
    MODERATE_RISK: 4,
    // 中等风险
    AT_RISK: 3,
    // 有风险
    IMPROVING: 2,
    // 改善中
    ENGAGED: 1,
    // 参与中
    FULL_ACTIVE: 0 // 完全活跃

  },

  /**
   * @description 机构NEW SIGNUP RISK状态
   */
  NEW_SIGNUP_RISK_STATUS_TYPE: {
    NORMAL: 0,
    FOUR_WEEK: 1,
    EIGHT_WEEK: 2,
    TWELVE_WEEK: 3
  },

  /**
   * @description 工单创建者类型
   */
  TICKET_CREATOR_TYPE: {
    CENTER: 1,
    // 机构
    TEACHER: 2,
    // 老师
    STAFF: 3 // 客服

  },

  /**
   * @description 工单状态
   */
  TICKET_STATUS: {
    OPEN: 1,
    // 新建
    IN_PROGRESS: 2,
    // 处理中
    WAITING_FOR_USER_RESPONSE: 3,
    // 等待中
    UNDER_REVIEW: 4,
    // 审核中
    ON_HOLD: 5,
    // 暂停
    RESOLVED: 6,
    // 已解决
    CLOSED: 7,
    // 已关闭
    REOPENED: 8 // 已重新打开

  },

  /**
   * @description 工单历史类型
   */
  TICKET_HISTORY_TYPE: {
    CREATE: 1,
    // 创建工单
    UPDATE: 2,
    // 修改工单
    STATUS: 3,
    // 状态变更
    ASSIGN: 4,
    // 分配工单
    COMMENT: 5 // 添加评论

  },

  /**
   * @description 工单优先级
   */
  TICKET_PRIORITY_TYPE: {
    URGENT: 0,
    HIGH: 1,
    MEDIUM: 2,
    LOW: 3
  },

  /**
   * @description 用户账号状态
   */
  USER_ACCOUNT_STATUS: {
    DISABLED: 0,
    // 禁用
    NORMAL: 1,
    // 正常
    LOGOUT: 2 // 已注销

  },

  /**
   * @description 分页相关
   */
  PAHE_TYPE: {
    PAGE: 0,
    // 页码
    SIZE: 99999999,
    // 每页大小
    PAGE_NO: 1,
    // 默认页码
    PAGE_SIZE: 99999999 // 默认每页大小

  },

  /**
   * @description 优惠券类型
   */
  COUPON_TYPE: {
    SIGN_UP: 1,
    // 注册
    MULTIPLE_BOOKING: 2 // 跨天预订

  },

  /**
   * @description 日期常量
   */
  DAYS: {
    SEVEN: 7
  },

  /**
   * @description dashboard常量
   */
  DASHBOARD_SEARCH_TYPE: {
    TEACHER: 'teacher',
    // 教师
    CANDIDATES: 'candidates',
    // 老师资源池
    CENTER: 'center' // 机构

  }
};
module.exports = {
  /**
   * @description 审核状态
   */
  USER_AUDIT_STATUS: {
    APPROVED: 1,
    // 审核通过
    DISAPPROVED: 2,
    // 驳回
    UNDER_APPROVAL: 3 // 待审核

  },

  /**
   * @description offer状态
   */
  OFFER_STATUS: {
    CANCELLED: 0,
    // 已取消
    INTERESTED: 1,
    // 感兴趣interested
    CONNECTING: 2,
    // 待接收
    EXPIRED: 3,
    // 已超期
    REJECTED: 4 // 已拒绝

  },

  /**
   * @description job invite状态
   */
  JOB_INVITE_STATUS: {
    CANCELLED: 0,
    // 已取消
    CONNECTED: 1,
    // 已连接
    CONNECTING: 2,
    // 连接中
    FAILED: 3,
    // 匹配失败
    SPLITTED: 4 // 拆单

  },

  /**
   * @description job Book状态
   */
  JOB_BOOK_STATUS: {
    PROCESSING: 2,
    // 进行中
    FINISHED: 3,
    // 已完成
    LEAVE: 4,
    // 请假
    CANCELLED: 5,
    // 取消
    EXTEND: 6 // 延时

  },

  /**
   * @description notes状态
   */
  EDIT_NOTES_TYPE: {
    CENTER: 1,
    // 机构
    TEACHER: 2,
    // 教师
    CENTER_POOL: 3,
    // 机构资源池
    TEACHER_POOL: 4 // 教师资源池

  },

  /**
   * @description 机构risk状态
   */
  RISK_STATUS_TYPE: {
    SIGNUP_NO_BOOKING: 7,
    NEW_SIGNUP: 6,
    HIGH_RISK: 5,
    MODERATE_RISK: 4,
    AT_RISK: 3,
    IMPROVING: 2,
    ENGAGED: 1,
    FULL_ACTIVE: 0
  },

  /**
   * @description 机构NEW SIGNUP RISK状态
   */
  NEW_SIGNUP_RISK_STATUS_TYPE: {
    NORMAL: 0,
    FOUR_WEEK: 1,
    EIGHT_WEEK: 2,
    TWELVE_WEEK: 3
  }
};
import request from '@/utils/request';
import { baseRouterUrl } from '@/api/config'; // 首页-Data Overview

export function getCount(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/home/panel/data/overview"),
    method: 'post',
    data: data
  });
} // 首页-Data chats

export function getCountChart(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/home/panel/data/charts"),
    method: 'post',
    data: data
  });
} // 数据统计-Overview

export function getOverviewCount(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/data/overview"),
    method: 'post',
    data: data
  });
} // 数据统计-OrderOverview

export function getOrderCount(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/order/overview"),
    method: 'post',
    data: data
  });
} // 数据统计-UserOverview

export function getUserCount(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/user/overview"),
    method: 'post',
    data: data
  });
} // 数据统计-Data Charts

export function getOrderChart(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/data/charts"),
    method: 'post',
    data: data
  });
} // 数据统计-（Centre data）

export function getCentreList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/center"),
    method: 'post',
    data: data
  });
} // 数据统计-（teacher data）

export function getTeacherList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/teacher"),
    method: 'post',
    data: data
  });
} // 数据统计-平均积分

export function getScoreDetails(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/teacher/score"),
    method: 'post',
    data: data
  });
} // 导出

export function download(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/centreData/export"),
    method: 'post',
    data: data
  });
} // 消息列表

export function getNoticeList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/message/list"),
    method: 'post',
    data: data
  });
} // 消息已读

export function setNoticeRead(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/message/set/read"),
    method: 'post',
    data: data
  });
} // 消息未读数

export function getNoticeUnreadNumber(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/message/not/read/number"),
    method: 'post',
    data: data
  });
} // 实时在线人数

export function getOnlineUsers(data) {
  return request({
    url: "".concat(baseRouterUrl, "/online/home/static"),
    method: 'post',
    data: data
  });
} // 实时在线人数列表

export function getOnlineUserList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/online/user/list"),
    method: 'post',
    data: data
  });
} // 历史在线人数图表

export function getHistoryOnlineUserChart(data) {
  return request({
    url: "".concat(baseRouterUrl, "/online/home/chart"),
    method: 'post',
    data: data
  });
} // 在线区域人数统计

export function getOnlineCityUserChart(data) {
  return request({
    url: "".concat(baseRouterUrl, "/online/city/chart"),
    method: 'post',
    data: data
  });
} // 在线区域人数统计

export function getSearchStatic(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/center/search/record/static"),
    method: 'post',
    data: data
  });
} // 导出消息

export function downloadNotice(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/message/export"),
    method: 'post',
    data: data
  });
} // 导出Leave on the 1st page数据

export function downloadBounce(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/export/center/job/process"),
    method: 'post',
    data: data
  });
} // 首页概览（Average Match Time）

export function getHomeAverageMatchTimeChart(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/home/average/match/time/charts"),
    method: 'post',
    data: data
  });
} // 机构进入页面后未创建工作的记录导出

export function downloadBoBoking(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/export/center/nobooking/record"),
    method: 'post',
    data: data
  });
}
export default {
  getCount: getCount,
  getCountChart: getCountChart,
  getOverviewCount: getOverviewCount,
  getOrderCount: getOrderCount,
  getUserCount: getUserCount,
  getOrderChart: getOrderChart,
  getCentreList: getCentreList,
  getTeacherList: getTeacherList,
  getScoreDetails: getScoreDetails,
  download: download,
  getNoticeList: getNoticeList,
  setNoticeRead: setNoticeRead,
  getNoticeUnreadNumber: getNoticeUnreadNumber,
  getOnlineUsers: getOnlineUsers,
  getOnlineUserList: getOnlineUserList,
  getHistoryOnlineUserChart: getHistoryOnlineUserChart,
  getOnlineCityUserChart: getOnlineCityUserChart,
  getSearchStatic: getSearchStatic,
  downloadNotice: downloadNotice,
  downloadBounce: downloadBounce,
  getHomeAverageMatchTimeChart: getHomeAverageMatchTimeChart,
  downloadBoBoking: downloadBoBoking
};
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";

/**
 * 定义一个名为 genderSearchOptions 的函数
 * 该函数用于生成一个包含性别搜索选项的数组
 * @returns {Array} 包含性别搜索选项的数组
 */
export function genderSearchOptions() {
  var exDat = [{
    key: this.$t('orderManagement.male'),
    display_name: this.$t('orderManagement.male')
  }, {
    key: this.$t('orderManagement.female'),
    display_name: this.$t('orderManagement.female')
  }, {
    key: this.$t('orderManagement.other'),
    display_name: this.$t('orderManagement.other')
  }];
  return exDat;
}
/**
 * 定义一个名为 genderSwitchOptions 的函数
 * 该函数用于生成一个包含性别转换选项的数组
 * @returns {Array} 包含性别转换选项的数组
 */

export function genderSwitchOptions() {
  var exDat = [this.$t('orderManagement.male'), this.$t('orderManagement.female'), this.$t('orderManagement.other')];
  return exDat;
}
/**
 * 定义一个名为 genderPreferredOptions 的函数
 * 该函数用于生成一个包含性别偏好选项的数组
 * @returns {Array} 包含性别偏好选项的数组
 */

export function genderPreferredOptions() {
  var exDat = [{
    key: this.$t('orderManagement.none'),
    display_name: this.$t('orderManagement.none')
  }, {
    key: this.$t('orderManagement.female'),
    display_name: this.$t('orderManagement.female')
  }, {
    key: this.$t('orderManagement.male'),
    display_name: this.$t('orderManagement.male')
  }];
  return exDat;
} // seeker状态

export function seekerStatusOptions() {
  var exDat = [{
    key: 1,
    display_name: this.$t('common.active')
  }, {
    key: 0,
    display_name: this.$t('common.disable')
  }];
  return exDat;
} // 单位转换米转化为千米

export function meterTokilometer(param) {
  var st = param / 1000;
  return st;
}
export function curriculumOptions() {
  var exDat = [{
    key: 5000,
    display_name: this.$t('orderManagement.curDistance01')
  }, {
    key: 10000,
    display_name: this.$t('orderManagement.curDistance02')
  }, {
    key: 15000,
    display_name: this.$t('orderManagement.curDistance03')
  }, {
    key: 20000,
    display_name: this.$t('orderManagement.curDistance04')
  }];
  return exDat;
} // 资质类型

export function teacherTalentPoolOptions() {
  var exDat = [{
    key: 1,
    display_name: this.$t('userManagement.qualified')
  }, {
    key: 2,
    display_name: this.$t('userManagement.unqualified')
  }];
  return exDat;
}
export function bookingPortalOptions() {
  var exDat = [{
    key: 0,
    display_name: this.$t('orderManagement.normal')
  }, {
    key: 1,
    display_name: this.$t('orderManagement.prebook')
  }, {
    key: 2,
    display_name: this.$t('orderManagement.jobSeeker')
  }];
  return exDat;
} // 优惠券使用范围

export function couponUserTypeOptions() {
  var exDat = [{
    key: 0,
    display_name: this.$t('operate.100off.useAll')
  }, {
    key: 1,
    display_name: this.$t('operate.100off.useQualified')
  }, {
    key: 2,
    display_name: this.$t('operate.100off.useUnqualified')
  }];
  return exDat;
} // 优惠券使用范围数据处理

export function couponUseRange(ki) {
  var exDat = {
    0: this.$t('operate.100off.useAll'),
    1: this.$t('operate.100off.useQualified'),
    2: this.$t('operate.100off.useUnqualified')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.100off.useAll');
  return tpitm;
} // 下载文件转换

export function downFile(url, fileName) {
  var x = new XMLHttpRequest();
  x.open('GET', url, true);
  x.responseType = 'blob';

  x.onload = function () {
    var url = window.URL.createObjectURL(x.response);
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  };

  x.send();
} // 教师channel类型

export function channel_type() {
  var exDat = [{
    key: 1,
    display_name: this.$t('userManagement.facebook')
  }, {
    key: 2,
    display_name: this.$t('userManagement.indeed')
  }, {
    key: 3,
    display_name: this.$t('userManagement.seek')
  }, {
    key: 4,
    display_name: this.$t('userManagement.tradeMe')
  }, {
    key: 5,
    display_name: this.$t('userManagement.wix')
  }, {
    key: 6,
    display_name: this.$t('userManagement.others')
  }];
  return exDat;
} // 工作public类型

export function invites_range() {
  var exDat = [{
    key: 1,
    display_name: this.$t('orderManagement.public')
  }, {
    key: 2,
    display_name: this.$t('orderManagement.favourite')
  }, {
    key: 3,
    display_name: this.$t('orderManagement.favPublic')
  }];
  return exDat;
} // 设置表格头部样式

export function headerStyle() {
  // return 'background: rgba(235, 235, 235, 0.92);'
  return 'background: #ededec;color:#555555';
} // 保留0位小数

export function numFilter(value) {
  var realVal = parseFloat(value).toFixed(0);
  return realVal;
} // 教师是否显示第一等级两级

export function teacherFirstRewardFlag(ki) {
  var exDat = {
    1: this.$t('common.yes'),
    0: this.$t('common.no')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 教师是否显示第一等级两级数组

export function teacher_first_reward_flag() {
  var exDat = [{
    key: 1,
    display_name: this.$t('common.yes')
  }, {
    key: 0,
    display_name: this.$t('common.no')
  }];
  return exDat;
} // 操作类型

export function manager_operate_type() {
  var exDat = [{
    key: 1,
    display_name: this.$t('common.operateLogin')
  }, {
    key: 2,
    display_name: this.$t('common.operateLogout')
  }];
  return exDat;
} // 操作类型

export function managerOperateType(ki) {
  var exDat = {
    1: this.$t('common.operateLogin'),
    2: this.$t('common.operateLogout')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.operateLogin');
  return tpitm;
} // 确认方式

export function jobConfirmType(ki) {
  var exDat = {
    1: 'User Confirm',
    2: 'System Auto'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'System Auto';
  return tpitm;
} // 文件标识

export function documentFlag(ki) {
  var exDat = {
    1: this.$t('userManagement.docVerified'),
    2: this.$t('userManagement.attentionDocs'),
    3: ''
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 犯罪文件上传标识

export function policeVettingFlag(ki) {
  var exDat = {
    1: this.$t('userManagement.submitted'),
    2: '',
    3: ''
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 犯罪文件上传标识颜色标识

export function policeVettingFlagColor(ki) {
  var exDat = {
    1: 'info',
    2: '',
    3: ''
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 犯罪文件上传标识

export function policeVettingResultFlag(ki) {
  var exDat = {
    1: this.$t('userManagement.clear'),
    2: this.$t('userManagement.failure'),
    3: ''
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 推荐人邮件是否发送

export function referenceCheckEmailFlag(ki) {
  var exDat = {
    1: this.$t('userManagement.sent'),
    2: '',
    3: ''
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 邮件反馈结果标识

export function referenceCheckEmailResultFlag(ki) {
  var exDat = {
    1: this.$t('userManagement.satisfied'),
    2: this.$t('userManagement.failure'),
    3: ''
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 工作年龄

export function ageGroup(ki) {
  var exDat = {
    1: this.$t('orderManagement.under2'),
    2: this.$t('orderManagement.over2'),
    3: this.$t('orderManagement.ageGroupMayVary')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 工作创建设备类型

export function jobCreateDeviceType(ki) {
  var exDat = {
    0: this.$t('orderManagement.unknown'),
    1: this.$t('orderManagement.mobile'),
    2: this.$t('orderManagement.desktop')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Unknown';
  return tpitm;
} // 私有订单邀请教师状态

export function jobInviteTeacherStatus(ki) {
  var exDat = {
    1: this.$t('orderManagement.connecting'),
    2: this.$t('orderManagement.unavailable'),
    3: this.$t('orderManagement.declined')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Connecting';
  return tpitm;
} // 设备类型

export function deviceType(ki) {
  var exDat = {
    0: 'NULL',
    1: 'Android',
    2: 'IOS',
    3: 'PC'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'NULL';
  return tpitm;
}
export function jobDeviceType(ki) {
  var exDat = {
    // 0: 'NULL',
    1: 'Android',
    2: 'IOS',
    3: 'Desktop'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Android';
  return tpitm;
} // 设备类型

export function job_device_type() {
  var exDat = [// { key: 0, display_name: this.$t('common.null') },
  {
    key: 1,
    display_name: this.$t('common.android')
  }, {
    key: 2,
    display_name: this.$t('common.ios')
  }, {
    key: 3,
    display_name: this.$t('orderManagement.desktop')
  }];
  return exDat;
} // 设备类型

export function device_type() {
  var exDat = [{
    key: 0,
    display_name: this.$t('common.null')
  }, {
    key: 1,
    display_name: this.$t('common.android')
  }, {
    key: 2,
    display_name: this.$t('common.ios')
  }];
  return exDat;
} // zip标识

export function zip_flag() {
  var exDat = [{
    key: 1,
    display_name: this.$t('common.yes')
  }, {
    key: 0,
    display_name: this.$t('common.no')
  }];
  return exDat;
} // 机构boost组别标识

export function centre_boost_group_flag() {
  var exDat = [{
    key: 1,
    display_name: this.$t('common.yes')
  }, {
    key: 0,
    display_name: this.$t('common.no')
  }];
  return exDat;
} // 机构组别标识

export function centre_group_flag() {
  var exDat = [{
    key: 1,
    display_name: this.$t('common.yes')
  }, {
    key: 0,
    display_name: this.$t('common.no')
  }];
  return exDat;
} // 消息类型

export function noticeType(ki) {
  var exDat = {
    1: 'Create Job',
    2: 'Applied',
    3: 'Cancelled (Book)',
    4: 'Leaved Early',
    5: 'Leaved',
    6: 'Extend',
    7: 'Split',
    8: 'Centre Boost',
    9: 'Decline',
    10: 'Failed',
    11: 'Connected',
    12: 'Teacher Sigin Up',
    13: 'Centre Sigin Up',
    14: 'Offer (Connecting)',
    15: 'Offer (Failed)',
    16: 'Offer (Connected)',
    17: 'Offer (Decline)',
    18: 'Offer (Cancelled)',
    19: 'Invite (Repost)',
    20: 'Cancelled (book)',
    21: 'Toggle on Permanent',
    22: 'Toggle off Permanent',
    23: 'Commuting Edited',
    24: 'Bookmark',
    25: 'Unbookmark',
    26: 'Create Job',
    27: 'Applied',
    28: 'Cancelled (Invite)',
    29: 'Failed',
    30: 'Declined',
    31: 'Dismissed',
    32: 'Favourited',
    33: 'Unfavourited',
    34: 'Blocked',
    35: 'Unblocked',
    36: 'Cancelled (invite)'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Platform';
  return tpitm;
} // 消息类型

export function messageTypeOptions() {
  var exDat = [{
    key: 1,
    display_name: 'Create Job'
  }, {
    key: 2,
    display_name: 'Applied'
  }, {
    key: 3,
    display_name: 'Cancelled (Book)'
  }, {
    key: 4,
    display_name: 'Leaved Early'
  }, {
    key: 5,
    display_name: 'Leaved'
  }, {
    key: 6,
    display_name: 'Extend'
  }, {
    key: 7,
    display_name: 'Split'
  }, {
    key: 8,
    display_name: 'Centre Boost'
  }, {
    key: 9,
    display_name: 'Decline'
  }, {
    key: 10,
    display_name: 'Failed'
  }, // { key: 11, display_name: 'Connected' },
  {
    key: 12,
    display_name: 'Teacher Sigin Up'
  }, {
    key: 13,
    display_name: 'Centre Sigin Up'
  }, {
    key: 14,
    display_name: 'Offer (Connecting)'
  }, {
    key: 15,
    display_name: 'Offer (Failed)'
  }, {
    key: 16,
    display_name: 'Offer (Connected)'
  }, {
    key: 17,
    display_name: 'Offer (Decline)'
  }, {
    key: 18,
    display_name: 'Offer (Cancelled)'
  }, {
    key: 19,
    display_name: 'Invite (Repost)'
  }, {
    key: 20,
    display_name: 'Cancelled (invite)'
  }, {
    key: 21,
    display_name: 'Toggle on Permanent'
  }, {
    key: 22,
    display_name: 'Toggle off Permanent'
  }, {
    key: 23,
    display_name: 'Commuting Edited'
  }, {
    key: 24,
    display_name: 'Bookmark'
  }, {
    key: 25,
    display_name: 'Unbookmark'
  }, {
    key: 26,
    display_name: 'Create Job'
  }, {
    key: 27,
    display_name: 'Applied'
  }, {
    key: 28,
    display_name: 'Cancelled (Book)'
  }, {
    key: 29,
    display_name: 'Failed'
  }, {
    key: 30,
    display_name: 'Declined'
  }, {
    key: 31,
    display_name: 'Dismissed'
  }, {
    key: 32,
    display_name: 'Favourited'
  }, {
    key: 33,
    display_name: 'Unfavourited'
  }, {
    key: 34,
    display_name: 'Blocked'
  }, {
    key: 35,
    display_name: 'Unblocked'
  }, {
    key: 36,
    display_name: 'Cancelled (Invite)'
  }];
  return exDat;
} // 消息类型颜色

export function noticeTypeColor(ki) {
  var exDat = {
    1: 'primary',
    2: 'success',
    3: 'warning',
    4: 'danger',
    5: 'info',
    6: 'primary',
    7: 'success',
    8: 'warning',
    9: 'danger',
    10: 'info',
    11: 'primary',
    12: 'success',
    13: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Platform';
  return tpitm;
} // 消息操作用户类型

export function noticeUserType(ki) {
  var exDat = {
    1: 'Centre',
    2: 'Teacher',
    3: 'Platform'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Platform';
  return tpitm;
} // 接单类型

export function applySource(ki) {
  var exDat = {
    1: 'Nature',
    2: 'Assigned'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Nature';
  return tpitm;
} // 系统消息操已读类型

export function updatesReadType(ki) {
  var exDat = {
    1: 'READ',
    0: 'UNREAD'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'UNREAD';
  return tpitm;
} // 系统消息已读未读类型颜色

export function updatesReadColor(ki) {
  var exDat = {
    0: 'danger',
    1: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'danger';
  return tpitm;
} // 教师channel类型

export function updates_read_type() {
  var exDat = [{
    key: 1,
    display_name: 'READ'
  }, {
    key: 0,
    display_name: 'UNREAD'
  }];
  return exDat;
} // 消息操已读类型

export function noticeReadType(ki) {
  var exDat = {
    1: 'READ',
    0: 'UNREAD'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'UNREAD';
  return tpitm;
} // 消息已读未读类型颜色

export function noticeReadColor(ki) {
  var exDat = {
    0: 'danger',
    1: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'danger';
  return tpitm;
} // 教师类型
// export function teacherFlag(ki) {
// 	var exDat = {
// 		1: 'Qualified Teacher',
// 		2: 'Unqualified Teacher '
// 	}
// 	var tpitm = exDat[ki]
// 	tpitm = tpitm || ''
// 	return tpitm
// }

export function teacherFlagShort(ki) {
  var exDat = {
    1: 'Qualified',
    2: 'Unqualified'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 教师类型颜色

export function teacherFlagColor(ki) {
  var exDat = {
    1: 'primary',
    2: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} //

export function favouriteFlagColor(ki) {
  var exDat = {
    1: 'primary',
    2: 'info',
    3: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
}
export function favouriteFlag(ki) {
  var exDat = {
    1: 'Yes',
    2: 'No',
    3: 'No'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
}
export function blockFlag(ki) {
  var exDat = {
    1: 'No',
    2: 'Yes',
    3: 'No'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
}
export function blockFlagColor(ki) {
  var exDat = {
    1: 'info',
    2: 'primary',
    3: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
} // 邀请类型

export function invitesRange(ki) {
  var exDat = {
    2: this.$t('orderManagement.favourite'),
    1: this.$t('orderManagement.public')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.public');
  return tpitm;
} // 邀请类型

export function invitesRangeColor(ki) {
  var exDat = {
    2: 'success',
    1: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 邀请来源

export function inviteSource(ki) {
  var exDat = {
    1: 'Original',
    2: 'Leave',
    3: 'Splited',
    4: 'Utopia Repost'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Original';
  return tpitm;
} // 用户状态

export function accountStatus(ki) {
  var exDat = {
    0: this.$t('common.disable'),
    1: this.$t('common.normal'),
    2: this.$t('common.logout')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.normal');
  return tpitm;
} // 发送对象

export function sendType(ki) {
  var exDat = {
    1: this.$t('systemManagement.systemInfo.allPlatforms'),
    2: this.$t('systemManagement.systemInfo.centre'),
    3: this.$t('systemManagement.systemInfo.teacher'),
    4: this.$t('systemManagement.systemInfo.qualifiedTeachers'),
    5: this.$t('systemManagement.systemInfo.unqualifiedTeachers'),
    11: this.$t('systemManagement.systemInfo.expiryTeachers'),
    12: this.$t('systemManagement.systemInfo.selectTeachers'),
    13: this.$t('systemManagement.systemInfo.selectCentres')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('systemManagement.systemInfo.allPlatforms');
  return tpitm;
} // 订单状态

export function orderStatus(ki) {
  var exDat = {
    0: this.$t('orderManagement.failed'),
    1: this.$t('orderManagement.confirmed'),
    2: this.$t('orderManagement.processing'),
    3: this.$t('orderManagement.finished'),
    4: this.$t('orderManagement.leave'),
    5: this.$t('orderManagement.canceled')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.failed');
  return tpitm;
} // 奖励来源

export function extraRewardType(ki) {
  var exDat = {
    0: 'None',
    1: 'Centre',
    2: 'Platform'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'None';
  return tpitm;
} // 工作记录状态

export function bookStatus(ki) {
  var exDat = {
    2: this.$t('orderManagement.processing'),
    3: this.$t('orderManagement.finished'),
    4: this.$t('orderManagement.leave'),
    5: this.$t('orderManagement.canceled'),
    6: this.$t('orderManagement.extend')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.processing');
  return tpitm;
} // offer 工作类型

export function workType(ki) {
  var exDat = {
    1: this.$t('orderManagement.fullTime'),
    2: this.$t('orderManagement.partTime')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.fullTime');
  return tpitm;
} // offer 工作时薪

export function rateRange(ki) {
  var exDat = {
    1: this.$t('orderManagement.rateRange1'),
    2: this.$t('orderManagement.rateRange2'),
    3: this.$t('orderManagement.rateRange3'),
    4: this.$t('orderManagement.rateRange4')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.rateRange1');
  return tpitm;
} // offer状态

export function offerStatus(ki) {
  var exDat = {
    0: 'Withdrawn',
    1: 'Interested',
    2: 'Connecting',
    3: 'Expired',
    4: 'Declined'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Connecting';
  return tpitm;
} // 教师类型

export function teacherFlag(ki) {
  var exDat = {
    1: 'Qualified Teacher',
    2: 'Unqualified Teacher '
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
}
export function substituteFlag(ki) {
  var exDat = {
    0: this.$t('common.yes'),
    1: this.$t('common.no')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
}
export function substituteColor(ki) {
  var exDat = {
    0: 'success',
    1: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 公共是否状态

export function commonFlag(ki) {
  var exDat = {
    0: this.$t('common.no'),
    1: this.$t('common.yes')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 工作邀请状态

export function inviteStatus(ki) {
  var exDat = {
    0: this.$t('orderManagement.canceled'),
    1: this.$t('orderManagement.confirmed'),
    2: this.$t('orderManagement.processing'),
    3: this.$t('orderManagement.failed'),
    4: this.$t('orderManagement.splitd')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.failed');
  return tpitm;
} // 工作邀请状态

export function JobInviteStatus(ki) {
  var exDat = {
    0: 'Cancelled',
    1: 'Connected',
    2: 'Connecting',
    3: 'Failed',
    4: 'Splitted'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Connecting';
  return tpitm;
} // 颜色

export function JobInviteStatusColor(ki) {
  var exDat = {
    0: 'info',
    1: 'primary',
    2: 'warning',
    3: 'info',
    4: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 订单类型

export function operateType(ki) {
  var exDat = {
    1: this.$t('orderManagement.billing'),
    2: this.$t('orderManagement.orders'),
    3: this.$t('orderManagement.cancellation'),
    4: this.$t('orderManagement.leaves'),
    5: this.$t('orderManagement.confirm')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.billing');
  return tpitm;
} // 是否跨天

export function jobStrideFlag(ki) {
  var exDat = {
    0: this.$t('common.no'),
    1: this.$t('common.yes')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 颜色

export function orderStatusColor(ki) {
  var exDat = {
    0: 'danger',
    1: 'warning',
    2: 'primary',
    3: 'success',
    4: 'danger',
    5: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 审核状态

export function auditStatus(ki) {
  var exDat = {
    1: this.$t('common.approved'),
    2: this.$t('common.reviewRejected'),
    3: this.$t('common.underTeview')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.underTeview');
  return tpitm;
} // 机构审核状态

export function auditCentreStatus(ki) {
  var exDat = {
    1: this.$t('common.approved'),
    2: this.$t('common.reviewRejected'),
    3: this.$t('common.underTeview')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.underTeview');
  return tpitm;
} // 机构审核状态

export function auditCentreMoeStatus(ki) {
  var exDat = {
    0: this.$t('userManagement.yetToSignup'),
    1: this.$t('common.approved'),
    2: this.$t('common.reviewRejected'),
    3: this.$t('common.underTeview')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('userManagement.yetToSignup');
  return tpitm;
} // 教师审核状态

export function auditTeacherStatus(ki) {
  var exDat = {
    1: this.$t('common.signedUp'),
    0: this.$t('userManagement.yetToSignup')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('userManagement.yetToSignup');
  return tpitm;
} // 性别

export function genderType(ki) {
  var exDat = {
    1: 'male',
    2: 'Female'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'male';
  return tpitm;
} // 教师级别更新类型

export function teacherRankType(ki) {
  var exDat = {
    1: this.$t('operate.teacherLevel.bronze'),
    2: this.$t('operate.teacherLevel.silver'),
    3: this.$t('operate.teacherLevel.black')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.teacherLevel.bronze');
  return tpitm;
} // 版本更新类型

export function versionUpdateType(ki) {
  var exDat = {
    1: this.$t('operate.version.generalUpdate'),
    2: this.$t('operate.version.silentUpdate'),
    3: this.$t('operate.version.forcedUpdate')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.version.generalUpdate');
  return tpitm;
} // 安装包版本类型

export function versionType(ki) {
  var exDat = {
    1: this.$t('operate.version.android'),
    2: this.$t('operate.version.ios')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.version.android');
  return tpitm;
} // 系统消息发布状态

export function pubType(ki) {
  var exDat = {
    0: this.$t('systemManagement.systemInfo.draftbox'),
    1: this.$t('systemManagement.systemInfo.published')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('systemManagement.systemInfo.none');
  return tpitm;
} // 优惠券使用状态

export function couponSta(ki) {
  var exDat = {
    1: 'Unused',
    2: 'Using',
    3: 'Used',
    4: 'Cancelled',
    5: 'Expired'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 优惠券使用状态颜色

export function couponStaColor(ki) {
  var exDat = {
    1: 'Warning',
    2: 'Warning',
    3: 'success',
    4: 'info',
    5: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 短信发送状态

export function smsSendStatus(ki) {
  var exDat = {
    0: 'failed',
    1: 'successed'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'failed';
  return tpitm;
} // 短信发送状态颜色

export function smsSendColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'warning';
  return tpitm;
} // 短信类型

export function smsType(ki) {
  var exDat = {
    0: 'Domestic',
    1: 'International'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Website';
  return tpitm;
} // 短信模板类型

export function smsTemplate(ki) {
  var exDat = {
    0: 'Ordinary SMS',
    1: 'Marketing SMS'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Website';
  return tpitm;
} // 系统消息发布状态

export function pubTypeColor(ki) {
  var exDat = {
    1: 'warning',
    2: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // xero状态

export function xeroStatus(ki) {
  var exDat = {
    1: 'Success',
    0: 'Failed',
    2: 'None'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'None';
  return tpitm;
} // xero状态

export function xeroStatusColor(ki) {
  var exDat = {
    1: 'success',
    0: 'danger',
    2: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'None';
  return tpitm;
} // 直播状态

export function liveStreamStatus(ki) {
  var exDat = {
    1: 'Upcoming',
    2: 'Inprogress',
    3: 'closed',
    4: 'Expired'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 直播状态颜色

export function liveStreamStatusColor(ki) {
  var exDat = {
    1: 'info',
    2: 'success',
    3: 'primary',
    4: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 颜色

export function commonColor(ki) {
  var exDat = {
    0: 'primary',
    1: 'success',
    2: 'warning',
    3: 'primary',
    4: 'danger',
    5: '',
    6: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 用户类型

export function userType(ki) {
  var exDat = {
    1: '超级管理员',
    2: '普通管理员'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 产品类型

export function productType(ki) {
  var exDat = {
    1: '芯片',
    2: '套件'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 授权类型

export function applyType(ki) {
  var exDat = {
    1: '销售录单',
    2: '客户申请'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审批状态

export function approveType(ki) {
  var exDat = {
    0: '待审批',
    1: '审批通过',
    2: '审批驳回'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 版本状态

export function programType(ki) {
  var exDat = {
    0: '待确认',
    1: '已确认'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 研发流程状态

export function processType(ki) {
  var exDat = {
    0: '申请授权',
    1: '商务审批',
    2: '研发确认'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审核颜色

export function approveColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success',
    2: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 版本颜色

export function programColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 流程颜色

export function processColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success',
    2: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 版本更新类型

export function programUpdateType(ki) {
  var exDat = {
    1: '正常更新',
    2: '紧急更新'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 英文转数字

export function englishToNum(ki) {
  var exDat = {
    first: 0,
    second: 1,
    three: 2,
    four: 3,
    five: 4,
    six: 5,
    seven: 6,
    eight: 7,
    nine: 8,
    ten: 9,
    eleven: 10,
    twelve: 11,
    thirteen: 12,
    fourteen: 13,
    fifteen: 14,
    sixteen: 15,
    seventeen: 16,
    eighteen: 17,
    nineteen: 18,
    twenty: 19
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 数字转英文

export function englishNum(ki) {
  var exDat = {
    0: 'first',
    1: 'second',
    2: 'three',
    3: 'four',
    4: 'five',
    5: 'six',
    6: 'seven',
    7: 'eight',
    8: 'nine',
    9: 'ten',
    10: 'eleven',
    11: 'twelve',
    12: 'thirteen',
    13: 'fourteen',
    14: 'fifteen',
    15: 'sixteen',
    16: 'seventeen',
    17: 'eighteen',
    18: 'nineteen',
    19: 'twenty',
    20: 'twenty-one'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 绑定状态

export function bindStatus(ki) {
  var exDat = {
    0: '未绑定',
    1: '已绑定'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 人脸绑定状态

export function faceStatus(ki) {
  var exDat = {
    0: '未认证',
    1: '已认证'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 激活状态

export function jhStatus(ki) {
  var exDat = {
    0: '未激活',
    1: '已激活'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 票类型

export function ticketType(ki) {
  var exDat = {
    0: '年票',
    1: '年票',
    2: '次票',
    3: '多次票',
    4: '暑期亲子卡'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 显示状态

export function showStatus(ki) {
  var exDat = {
    0: '否',
    1: '是'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 显示状态颜色

export function showStatusColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 余额类型

export function accountType(ki) {
  var exDat = {
    1: '充值',
    2: '消费',
    3: '提现',
    4: '提现失败退回',
    5: '看视频',
    6: '平台调整(增加)',
    7: '平台调整(减少)'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 启用、禁用状态

export function useSta(ki) {
  var exDat = {
    0: '禁用',
    1: '启用'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 启用、禁用颜色

export function useStaColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 推荐状态

export function positionSta(ki) {
  var exDat = {
    0: '否',
    1: '是'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审核状态

export function audSta(ki) {
  var exDat = {
    0: '等待审核',
    1: '审核通过',
    2: '审核拒绝'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审核状态颜色

export function audStaColor(ki) {
  var exDat = {
    0: 'info',
    1: 'success',
    2: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 订单状态

export function orderSta(ki) {
  var exDat = {
    0: '待支付',
    1: '已支付',
    2: '已取消',
    3: '已退款'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 订单状态颜色

export function orderStaColor(ki) {
  var exDat = {
    0: 'info',
    1: 'success',
    2: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 支付方式

export function payType(ki) {
  var exDat = {
    1: '在线支付',
    2: '线下支付'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 支付方式

export function payMethod(ki) {
  var exDat = {
    alipaypage: '支付宝电脑网站',
    alipaywap: '支付宝手机网站',
    offline: '线下支付'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 订单每步信息

export function formatStepStatus(ki) {
  var exDat = {
    0: 1,
    10: 2,
    30: 3,
    40: 4
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 0;
  return tpitm;
} // 线下支付 状态

export function payStatus(ki) {
  var exDat = {
    0: '未支付',
    100: '未支付',
    200: '已支付'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 0;
  return tpitm;
} // 线下支付 颜色

export function payStatusColor(ki) {
  var exDat = {
    0: 'warning',
    100: 'warning',
    200: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 是否为空

export function nulljk(str) {
  var val = true;

  if (str === '' || str === null || str === undefined || str === 'undefined') {
    val = false;
  }

  return val;
} // 获取周几

export function getWeek(time, short) {
  short = short || false;
  var week = time.day();

  switch (week) {
    case 1:
      return short ? 'Mon' : 'Monday';

    case 2:
      return short ? 'Tue' : 'Tuesday';

    case 3:
      return short ? 'Wed' : 'Wednesday';

    case 4:
      return short ? 'Thu' : 'Thursday';

    case 5:
      return short ? 'Fri' : 'Friday';

    case 6:
      return short ? 'Sat' : 'Saturday';

    case 0:
      return short ? 'Sun' : 'Sunday';
  }
} // 上下架

export function useStaUp(ki) {
  var exDat = {
    0: '下架',
    1: '上架'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 屏蔽状态

export function pbSta(ki) {
  var exDat = {
    0: '启用',
    1: '禁用'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 屏蔽颜色

export function pbStaColor(ki) {
  var exDat = {
    0: 'success',
    1: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 清除空格

export function clearSpace(v) {
  if (!v) {
    return '';
  }

  return v.toString().replace(/(^\s+)|(\s+$)/g, '');
} // 校验是否为空

export function checkEmpty(v) {
  v = clearSpace(v);

  if (v === '' || !v) {
    return true;
  } else {
    return false;
  }
} // 是否跨天-5天

export function fiveDaysBookingFlag(ki) {
  var exDat = {
    0: this.$t('common.no'),
    1: this.$t('common.yes'),
    2: this.$t('common.yes')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 是否跨天-5天颜色

export function fiveDaysBookingFlagColor(ki) {
  var exDat = {
    0: 'primary',
    1: 'danger',
    2: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 奖励券类型

export function couponType(ki) {
  var exDat = {
    1: this.$t('operate.100off.centreRegistration'),
    2: this.$t('operate.100off.multipleBooking')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.100off.centreRegistration');
  return tpitm;
}
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import _objectSpread from "/home/runner/work/utopia-management/utopia-management/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger'; // import Doc from '@/components/Doc'

import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import Search from '@/components/HeaderSearch';
import DateRangePicker from '@/components/DateRangePicker'; // import CRUD from '@crud/crud'
// 方法引入
//

import { getNoticeList, setNoticeRead, getNoticeUnreadNumber, downloadNotice } from '@/api/statistics/statistics'; // 获取城市信息

import { getAllCitys as _getAllCitys } from '@/api/operate/centreFeeSchedule'; // 公共方法配置文件-自定义

import { noticeUserType, commonColor, noticeReadColor, noticeReadType, noticeType, messageTypeOptions, downFile, messageTypeColor } from '@/utils/common'; // 时间

var moment = require('moment');

export default {
  components: {
    DateRangePicker: DateRangePicker,
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    Screenfull: Screenfull,
    SizeSelect: SizeSelect,
    Search: Search // Doc

  },
  data: function data() {
    return {
      Avatar: '',
      dialogVisible: false,
      dialogMessageVisible: false,
      dialogData: {
        list: [],
        total: 0,
        multipleSelection: [],
        listQuery: {
          keyWord: null,
          isRead: '',
          messageType: '',
          userType: '',
          pageNo: 1,
          pageSize: 10,
          platform: 1,
          cityCode: '',
          startTime: '',
          endTime: ''
        }
      },
      timer: '',
      noticeNumber: 0,
      setSta: false,
      citys: [],
      downloadLoading: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'device', 'user', 'baseApi'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    }
  }),
  created: function created() {
    this.Avatar = this.$store.state.user.user.avatar;
    this.getCount(); // 城市数据
    // this.getAllCitys()
  },
  // DOM渲染完成
  mounted: function mounted() {
    this.timer = setInterval(this.getCount, 30000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    noticeUserType: noticeUserType,
    noticeReadType: noticeReadType,
    noticeReadColor: noticeReadColor,
    commonColor: commonColor,
    noticeType: noticeType,
    messageTypeOptions: messageTypeOptions,
    moment: moment,
    downFile: downFile,
    messageTypeColor: messageTypeColor,
    // 消息未读数据
    getCount: function getCount() {
      var _this = this;

      getNoticeUnreadNumber().then(function (res) {
        _this.noticeNumber = Number(res.rtn_data.number);
      });
    },
    showMessageList: function showMessageList() {
      this.dialogMessageVisible = true;
      this.getAllCitys();
      this.getDialogList();
    },
    handleSelectSearch: function handleSelectSearch() {
      this.getDialogList();
    },
    handleDialogSizeChange: function handleDialogSizeChange(val) {
      this.dialogData.listQuery.pageNum = 1;
      this.dialogData.listQuery.pageSize = val;
      this.getDialogList();
    },
    handleDialogCurrentChange: function handleDialogCurrentChange(val) {
      this.dialogData.listQuery.pageNum = val;
      this.getDialogList();
    },
    handleDialogSelectionChange: function handleDialogSelectionChange(val) {
      this.dialogData.multipleSelection = val;
    },
    getDialogList: function getDialogList() {
      var _this2 = this;

      console.log(this.dialogData.listQuery.aTime);

      if (this.dialogData.listQuery.aTime !== undefined && this.dialogData.listQuery.aTime) {
        this.dialogData.listQuery.startTime = this.dialogData.listQuery.aTime[0];
        this.dialogData.listQuery.endTime = this.dialogData.listQuery.aTime[1];
      }

      getNoticeList(this.dialogData.listQuery).then(function (response) {
        _this2.dialogData.list = response.rtn_data.list;
        _this2.dialogData.total = response.rtn_data.totalCount;
      });
    },
    // 批量设置已读
    batchSetRead: function batchSetRead() {
      var _this3 = this;

      // console.log('选择数据打印------------')
      // console.log(this.dialogData.multipleSelection)
      var noticeId = [];
      this.dialogData.multipleSelection.forEach(function (column) {
        noticeId.push(column.noticeId);
      });

      if (noticeId.length === 0) {
        this.$notify.warning({
          title: 'Please select at least one data',
          type: 'warning'
        });
        return;
      }

      var _data = {
        noticeId: noticeId
      };
      this.setSta = true;
      setNoticeRead(_data).then(function (res) {
        if (res.rtn_code === 0) {
          _this3.getDialogList();

          _this3.getCount();

          _this3.$notify({
            title: res.rtn_msg,
            type: 'success'
          });
        } else {
          _this3.$notify.warning({
            title: res.rtn_msg,
            type: 'warning'
          });
        }

        _this3.setSta = false;
      }).catch(function () {
        _this3.setSta = false;
      });
    },
    // 设置已读
    setRead: function setRead(row) {
      var _this4 = this;

      if (this.setSta) {
        return false;
      }

      if (!row.noticeId) {
        this.$notify.warning({
          title: this.$t('common.parameterError'),
          type: 'warning'
        });
        return;
      }

      var _data = {
        noticeId: [row.noticeId]
      };
      this.setSta = true;
      setNoticeRead(_data).then(function (res) {
        if (res.rtn_code === 0) {
          _this4.getDialogList();

          _this4.getCount();

          _this4.$notify({
            title: res.rtn_msg,
            type: 'success'
          });
        } else {
          _this4.$notify.warning({
            title: res.rtn_msg,
            type: 'warning'
          });
        }

        _this4.setSta = false;
      }).catch(function () {
        _this4.setSta = false;
      }); // this.$confirm(this.$t('orderManagement.setNoticeTips'), this.$t('common.tips'), {
      //   confirmButtonText: this.$t('common.confirm'),
      //   cancelButtonText: this.$t('common.cancel'),
      //   type: 'warning'
      // })
      //   .then(() => {
      //     this.setSta = true
      //     setNoticeRead(_data)
      //       .then((res) => {
      //         if (res.rtn_code === 0) {
      //           this.getDialogList()
      //           this.getCount()
      //           this.$notify({
      //             title: res.rtn_msg,
      //             type: 'success'
      //           })
      //         } else {
      //           this.$notify.warning({
      //             title: res.rtn_msg,
      //             type: 'warning'
      //           })
      //         }
      //         this.setSta = false
      //         this.delLoading = false
      //       })
      //       .catch(() => {
      //         this.setSta = false
      //       })
      //   })
      //   .catch(() => {
      //     this.setSta = false
      //     this.$notify.info({
      //       title: this.$t('common.cancel'),
      //       message: this.$t('common.operationCancelled')
      //     })
      //   })
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open: function open() {
      var _this5 = this;

      this.$confirm(this.$t('common.confirmLogout'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(function (e) {
        // console.log(e)
        _this5.logout();
      }).catch(function (e) {// console.log(e)
      });
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
      });
    },
    // 获取城市数据信息
    getAllCitys: function getAllCitys() {
      var _this6 = this;

      _getAllCitys().then(function (res) {
        _this6.citys = res.rtn_data.list;
      }).catch(function () {});
    },
    // 导出消息
    doExport: function doExport() {
      var _this7 = this;

      if (this.downloadLoading) {
        return false;
      }

      this.$confirm(this.$t('userManagement.export'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(function () {
        _this7.downloadLoading = true; // 确认操作 执行方法

        _this7.delLoading = true;
        downloadNotice(_this7.dialogData.listQuery).then(function (res) {
          var date = res.url || res.rtn_data.url; // window.open(date)

          var dates = moment().format('DD-MM-YYYY');
          downFile(date, 'Message ' + dates + '.xls');

          _this7.crud.refresh();

          _this7.$notify({
            title: res.rtn_msg,
            type: 'success'
          });

          _this7.downloadLoading = false;
          _this7.delLoading = false;
        }).catch(function () {
          _this7.downloadLoading = false;
          _this7.delLoading = false;
        });
      }).catch(function () {
        _this7.downloadLoading = false;

        _this7.$notify.info({
          title: _this7.$t('common.cancel'),
          message: _this7.$t('common.operationCancelled')
        });
      });
    },
    // 时间搜索
    handleDateChange: function handleDateChange() {
      console.log(this.dialogData.listQuery.aTime);

      if (this.dialogData.listQuery.aTime !== undefined && this.dialogData.listQuery.aTime) {
        this.dialogData.listQuery.startTime = this.dialogData.listQuery.aTime[0];
        this.dialogData.listQuery.endTime = this.dialogData.listQuery.aTime[1];
      } else {
        this.dialogData.listQuery.startTime = '';
        this.dialogData.listQuery.endTime = '';
      }

      this.getDialogList();
    }
  }
};